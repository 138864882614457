import React from 'react'
import Section1 from '../pages/homepage/Section1'
import Section2 from '../pages/homepage/Section2'
import Section3 from '../pages/homepage/Section3'
import Section4 from '../pages/homepage/Section4'
import Section5 from '../pages/homepage/Section5'
import Section6 from '../pages/homepage/Section6'
import Section5A from '../pages/homepage/Section5A'
import { Helmet } from 'react-helmet'
import Soc2compliant from '../pages/homepage/Soc2compliant'
import Section3DM from '../pages/products-pages/Section3DM'
// import VooshYearWrap2024 from '../pages/homepage/VooshYearWrap2024'

const Landingpage = () => {

  return (
    <div style={{ backgroundColor: '#F3F3F3' }}>
      <Helmet>
        <title>Voosh : Third-Party Dispute Resolution, Promotions, Finance, Downtime, and Reviews - AUTOMATED FOR YOU!!</title>
        <meta name='description' content='Instantly recover chargebacks, create and manage promotions with AI-driven insights, and respond to customer reviews across all platforms—either using AI or manually. Keep your restaurant online with real-time downtime control. Gain clear insights into payout discrepancies, reconcile POS data with off-premise orders, and access detailed transaction insights—all in one place.' />
        <meta name='keywords' content='Voosh, Restaurant Technology, 3rd Party Marketplace Management, Restaurant Marketplace Management, Third-Party Marketplace Optimization, Restaurant Revenue Recovery, Restaurant Digital Transformation, Restaurant Data-Driven Decisions, Restaurant Promotions Management, Restaurant Review Management, Restaurant Reconciliation Solutions, Restaurant Industry Digital Age, Unified Commerce Platform for Restaurants, Restaurant Technology Suite, Restaurant Operational Efficiency, Restaurant Profitability Optimization, Restaurant Industry Trends, Restaurant Industry Challenges' />
      </Helmet>
      <Section1 />
      {/* <VooshYearWrap2024 /> */}
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section3DM />
      <Section5A />
      <Soc2compliant />
      <Section6 />
    </div>
  )
}

export default Landingpage
