import React from 'react'

import Brand1 from '../../assets/images/partners/1.webp'
import Brand2 from '../../assets/images/partners/2.webp'
import Brand3 from '../../assets/images/partners/3.webp'
import Brand4 from '../../assets/images/partners/4.webp'
import Brand5 from '../../assets/images/partners/5.webp'
import Brand6 from '../../assets/images/partners/6.webp'
import Brand7 from '../../assets/images/partners/7.webp'
import Brand8 from '../../assets/images/partners/8.webp'
import Brand9 from '../../assets/images/partners/9.webp'
import Brand10 from '../../assets/images/partners/10.webp'
import Brand11 from '../../assets/images/partners/11.webp'
import Brand12 from '../../assets/images/partners/12.webp'
import Brand13 from '../../assets/images/partners/13.webp'
import Brand14 from '../../assets/images/partners/14.webp'
import Brand15 from '../../assets/images/partners/15.webp'
import Brand16 from '../../assets/images/partners/16.webp'
import Brand17 from '../../assets/images/partners/17.webp'
import Brand18 from '../../assets/images/partners/18.webp'
import Brand19 from '../../assets/images/partners/19.webp'
import Brand20 from '../../assets/images/partners/20.webp'
import Brand21 from '../../assets/images/partners/21.webp'
import Brand22 from '../../assets/images/partners/22.webp'
import Brand23 from '../../assets/images/partners/23.webp'


const Section3 = () => {
  return (
    <div className="s3-container">
      <div className="section2_brands f-width m-auto">
        <p className="s2b_text text-center">OUR PARTNERS</p>
        <div className="brands-list py-3">
          <div className='wrapper'>
            <img src={Brand1} alt="Brand1" />
            <img src={Brand2} alt="Brand2" />
            <img src={Brand3} alt="Brand3" />
            <img src={Brand4} alt="Brand4" />
            <img src={Brand5} alt="Brand5" />
            <img src={Brand6} alt="Brand6" />
            <img src={Brand7} alt="Brand7" />
            <img src={Brand8} alt='Brand8' />
            <img src={Brand9} alt="Brand9" />
            <img src={Brand10} alt="Brand10" />
            <img src={Brand11} alt="Brand11" />
            <img src={Brand12} alt="Brand12" />
            <img src={Brand13} alt="Brand13" />
            <img src={Brand14} alt="Brand14" />
            <img src={Brand15} alt="Brand15" />
            <img src={Brand16} alt="Brand16" />
            <img src={Brand17} alt="Brand17" />
            <img src={Brand18} alt="Brand18" />
            <img src={Brand19} alt="Brand19" />
            <img src={Brand20} alt="Brand20" />
            <img src={Brand21} alt="Brand21" />
            <img src={Brand22} alt="Brand22" />
            <img src={Brand23} alt="Brand23" />
            <img src={Brand1} alt="Brand1" />
            <img src={Brand2} alt="Brand2" />
            <img src={Brand3} alt="Brand3" />
            <img src={Brand4} alt="Brand4" />
            <img src={Brand5} alt="Brand5" />
            <img src={Brand6} alt="Brand6" />
            <img src={Brand7} alt="Brand7" />
            <img src={Brand8} alt='Brand8' />
            <img src={Brand9} alt="Brand9" />
            <img src={Brand10} alt="Brand10" />
            <img src={Brand11} alt="Brand11" />
            <img src={Brand12} alt="Brand12" />
            <img src={Brand13} alt="Brand13" />
            <img src={Brand14} alt="Brand14" />
            <img src={Brand15} alt="Brand15" />
            <img src={Brand16} alt="Brand16" />
            <img src={Brand17} alt="Brand17" />
            <img src={Brand18} alt="Brand18" />
            <img src={Brand19} alt="Brand19" />
            <img src={Brand20} alt="Brand20" />
            <img src={Brand21} alt="Brand21" />
            <img src={Brand22} alt="Brand22" />
            <img src={Brand23} alt="Brand23" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section3
